import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Ishant Singh Rathore',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'In the terms of Trainer Fitclub Has a well-trained Trainer who motivate you to workout with Proper-Diet ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:' one of the greatest dicision i have made ever in the life Thank you Fitclub',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
